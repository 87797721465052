import translationFr from './fr';

const translationEn: typeof translationFr = {
    metadata: {
        description: 'Online ordering for restaurants by Innovorder',
    },
    add: 'Add',
    back: 'Back',
    order_closed: 'Online ordering closed',
    open_at: 'Open {{date}} at {{hours}}:{{minutes}}',
    open_tomorrow_at: 'Open tomorrow at {{hours}}:{{minutes}}',
    open_today_at: 'Open today at {{hours}}:{{minutes}}',
    rush_delay_badge: '🕒 {{rushDelay}} min',
    rush_delay:
        'Our chefs have their work cut out for them!<br>Your waiting time has been extended by : {{rushDelay}} minutes',
    payment: {
        title: 'Payment',
        mode: 'Payment Mode',
        crypted_data: 'All payment details are transferred and encrypted by our payment partner',
        bank_card: 'Bank Card',
        confirm: 'Confirm',
        google_pay: 'Google Pay',
        apple_pay: 'Apple Pay',
        edenred: 'Edenred',
        paygreen: {
            label: 'Luncheon Vouchers',
            description: 'Bimpli, Pass Resto, Up Déjeuner, Swile, Restoflash',
        },
        choice: {
            luncheon_voucher: 'Pay with a Luncheon Voucher',
        },
        luncheon_voucher: 'Luncheon Voucher',
        luncheon_voucher_errors: {
            cart_with_non_eligible_products: 'Your cart is not eligible for Luncheon Vouchers',
            maximum_daily_amount: 'Can only be used for amounts under or equal to 25 euros',
        },
        cash: 'Cash',
        payment_later: 'Payment at the cash desk',
    },
    edenred: {
        authentication: {
            title: 'Edenred',
            description:
                'If the authentication page has not opened, please access it via the access button below:',
            auth_button_url: 'Authentication link',
            success_message: 'Successful authentication',
            error: 'An error occurred',
            there_is: 'There is',
            on_your_daily_balance: 'on your daily balance',
        },
    },
    card_information: 'Card Information',
    additional_user_information: 'Additional customer information',
    order_confirmation: 'Order Confirmation',
    disconnected_warning:
        'Your browser seems to be disconnected.<br/>Refresh the current page for following your order!',
    stripe_confirm: 'Sending your order... Please do not refresh the page!',
    order_loading: 'Confirming your order... Please do not refresh the page!',
    order_follow_up: {
        route: 'Order follow up',
        order_confirm: 'Confirming your order... Please do not refresh the page!',
        title: 'In preparation',
        multi_order_date: ' - Order placed at ',
        order_number: 'n°{{orderNumber}}',
        description: 'Keep this page open to follow the preparation of your order in real time.',
        explanations: {
            title_first_part: 'How to',
            title_highlighted_part: 'get your order',
            step_1: 'Do not close your tab for tracking your order',
            step_2: 'Follow the progress of its preparation on your screen',
            step_3: "It's ready!<br/>Pick up your order at the corresponding stand.",
            understood: 'Understood!',
        },
    },
    confirm_and_pay: 'Confirm and pay',
    confirm_and_pay_with_amount: 'Confirm and pay • {{amount, string}}',
    ewallet: 'Ewallet',
    menu_home: 'Menu',
    restaurants: {
        title: 'Restaurants',
        no_restaurants: 'No restaurants available 😪',
        no_restaurant_error: 'There are no enabled restaurants on this brand',
        subtitle: {
            wrong_schedule:
                'Check the opening of your restaurant in the innovorder reception and backoffice.',
            wrong_mode:
                'None of the restaurants are compatible with your current mode of consumption.',
            no_image: 'None of the restaurants has a thumbnail.',
        },
    },
    loading: 'Loading',
    loading_menu_error: 'A problem occurred while downloading the menu',
    empty_product_list: ' This category seems to be empty for now 😢',
    button_show_basket: 'Show my basket • {{amount, string}}',
    button_pay: 'Pay • {{amount, string}}',
    button_next: 'Next',
    product_name_route: 'Product',
    product_doesnt_exist: "We are sorry, this product doesn't exist anymore.. 😢",
    total: 'Total',
    add_to_cart_with_price: 'Add to cart • {{amount, string}}',
    product_additional_information: 'Additional information',
    error_title: 'Oops...',
    qrcode_error: 'Foodcourt mode deactivated. Please re-scan the qrcode.',
    no_consumption_mode: 'No activated consumption mode for this restaurant',
    not_found_message: "The page you're looking for doesn't seem to exist.",
    not_found_back_to_home: 'Go back home',
    cart: {
        title: 'Cart',
        order: 'My order',
        empty: 'Your cart is empty 😪',
        empty_subtitle: 'Please click on the back button to complete your order',
        empty_cta: 'Back to menu',
        stepper: {
            cart: 'Cart',
            name: 'Name',
            payment: 'Payment',
            preparation: 'Preparation',
            summary: 'Summary',
        },
        mode: {
            title: 'Consumption mode',
            sit_in: 'Sit in',
            take_away: 'Take away',
            modal: {
                title: 'You are going to change your <strong>consumption mode.</strong>',
                subtitle: 'Your <strong>cart</strong> will be emptied.',
                question: 'Do you want to continue ?',
                close: 'No, thanks',
                action: 'Continue',
            },
        },
        discount: {
            title: 'Discount',
            add: 'Add promocode',
            validate: 'Add',
        },
    },
    user_information: {
        title: 'Enter your first name ✍️',
        name: 'Name',
        last_name: 'Last Name',
        email: 'E-mail',
        name_description: 'To collect your order',
        mandatory: '*',
        input_placeholder: 'Jane',
    },
    price_break_down: {
        title: 'Summary',
        subtotal: 'Subtotal',
        taxes: 'Taxes',
        total: 'Total',
        discount: 'Discount {{ name }}',
    },
    go_to_payment: 'Proceed to payment',
    order_validated: 'Order validated',
    order_confirmation_title: 'Order number',
    order_number_short: '#{{ number }}',
    order_confirmation_content:
        "It's in the kitchen! Your order will soon be brought to your table",
    close: 'close',
    cart_error: 'An error occurred',
    cart_error_title: 'Oops...',
    cart_error_content:
        'One of your products is no longer available for sale, it was removed from your cart.',
    back_to_cart: 'Back to cart',
    order_error: 'An error occurred',
    order_error_title: 'Payment error',
    order_error_content: 'An error occurred during payment, please try again.',
    order_error_stripe: 'Temporarily unavailable',
    order_error_3ds: {
        title: 'Unusual loading time, did you get a validation request from your bank?',
        button: 'I have not received a validation request',
        modal_title: '3D secure support',
        modal_description:
            'Your current browser does not support 3D secure correctly. You have not been charged. We advise you to try another browser or another payment method.',
        modal_button: 'I understand',
    },
    retry: 'Retry',
    terms_and_conditions: {
        title: 'Terms And Conditions',
        title_short: 'T&Cs',
        accept: 'I acknowledge having read the',
        price_title: 'Price',
        price_content:
            'In general, the prices indicated on our site are not contractual, they are given for information only. Prices are always expressed inclusive of VAT unless otherwise indicated. {{name, string}} reserves the right to modify its prices at any time but undertakes to apply the prices in force which will have been indicated to You at the time of your order.',
        payment_terms_title: 'Payment Terms',
        payment_terms_content:
            'You can pay by credit card: payment is made on the secure banking server of our partner Stripe. This means that no banking information concerning you passes through the site of {{name, string}}. Payment by credit card is therefore perfectly secure; your order will thus be registered and validated as soon as the payment is accepted by the bank.',
        securing_title: 'Securing',
        securing_content:
            'Your credit card details are encrypted using the SSL (Secure Socket Layer) protocol and are never transmitted unencrypted over the network. Payment is made directly to the bank. {{name, string}} has no access to these details and does not keep them on its servers. This is why they are requested from You with each new transaction on our site.',
        online_order_title: 'Online Order',
        online_order_content:
            'Taking an order on the {{name, string}} Website requires your prior registration to create a {{name, string}} customer account.',
        registration_title: '1-Registration to the Website {{name, string}}',
        registration_content:
            'When registering for the {{name, string}} Website, You must choose an identifier associated with a valid e-mail address and a password. It is your responsibility to keep this information strictly confidential and not to communicate it to third parties, in order to avoid as far as possible any risk of intrusion into your customer account and the placing of orders without your knowledge by unauthorized persons. {{name, string}} cannot be held responsible for any use of your customer account by a third party who has had access to your username and password, whether on your initiative or through your negligence. When registering for the {{name, string}} Website, You must also provide a valid delivery address located within our delivery areas. The delivery address that You indicate when you register must be as precise as possible (building number, floor, digicode, etc.), in order to allow the delivery person to bring your order to You in the best conditions and the best time limit. {{name, string}} cannot be held responsible in case of impossibility of delivery due to erroneous or incomplete information. Once you have registered, a summary e-mail will be sent to You at the e-mail address that You will have previously provided.',
        login_title: '2-Login to the Website {{name, string}}',
        login_content:
            "Before each order, You must connect to the customer area of the {{name, string}} Website by clicking on the 'Connection' link on the home page. If you lose or forget your password, you can reset it and choose a new one by clicking on the 'Forgot password' link and entering your email address. You will then receive an e-mail to the e-mail address indicated, if it is recognized, allowing You to choose a new password.",
        selection_title: '3-Selection of dishes and menus on the Website {{name, string}}',
        selection_content:
            'The products presented on the {{name, string}} menu are subject to the availability of stocks in the {{name, string}} restaurant in whose jurisdiction the place of delivery is located. If stocks are exhausted, your {{name, string}} restaurant may, at its sole discretion, either contact You by telephone at the number You indicated when You registered to suggest that You opt for another product, or reduce the invoice amount by the price of the unavailable product. This reduction may also take the form of a credit note to be applied to a future order.',
        validation_title: '4-Validation of the order',
        validation_content:
            'Once the selection of products and menus has been made, You are asked to validate your basket and then indicate the method of payment chosen (cash or restaurant vouchers upon delivery, credit card after validation of the order). The {{name, string}} Website accepts payments by Carte Bleue VISA and Master Card through Stripe, a secure internet payment service company. When this operation is carried out, your order is recorded automatically and becomes firm and final, in accordance with the legal provisions excluding the exercise of the right of withdrawal in the distance selling of perishable products.',
        signature_title: '5-Electronic signature',
        signature_content:
            'Once the selection of products and menus has been made, You are asked to validate your basket and then indicate the method of payment chosen (cash or restaurant vouchers upon delivery, credit card after validation of the order). The {{name, string}} Website accepts payments by Carte Bleue VISA and Master Card through Stripe, a secure internet payment service company. When this operation is carried out, your order is recorded automatically and becomes firm and final, in accordance with the legal provisions excluding the exercise of the right of withdrawal in the distance selling of perishable products.',
        delivery_title: 'Delivery',
        delivery_content:
            "In a constant quest for quality, {{name, string}} ensures compliance with delivery times. It is the Customer's responsibility to check the status of the order on delivery and to inform the Agent's customer service within 24 hours of any complaints that he wishes to bring to the Agent's attention. The deliverers do not have any change on them, please top up.",
    },
    header_table_number: "You're seated at table nº{{tableNumber, string}}",
    validate: 'Validate',
    comment: 'Comment',
    add_comment: 'Add comment',
    get_my_receipt_by_email: 'Get my receipt by email',
    enter_email: 'Enter your email address',
    send: 'Send',
    email_sent: 'Email sent',
    invalid_email: 'Invalid email address',
    order_detail: 'Order recap',
    email: 'Email',
    mandatory: 'Mandatory',
    included_options_one: '1 included option.',
    included_options_other: '{{maxChoice, number}} included options.',
    pick_number_max: 'Pick {{maxChoice, number}} max.',
    pick_number_min: 'Pick {{minChoice, number}} min.',
    save: 'Save',
    customization_required_one: '{{count}} customization required',
    customization_required_other: '{{count}} customizations required',
    locate_kiosks: 'Locate kiosks',
    ticket_status_collected: 'Collected',
    ticket_status_cancelled: 'Cancelled',
    ticket_status_waiting: 'Waiting',
    ticket_status_processing: 'In progress',
    ticket_status_ready: 'Ready',
    modal_product_error: {
        title_oops: 'Oops...',
        body_generic_product_name: 'This product',
        body_not_available_one:
            '<strong>{{productsName}}</strong> is victim of its success and not available anymore',
        body_not_available_other:
            '<strong>{{productsName}}</strong> are victims of their own success and not available anymore',
        body_removed_product:
            'One of your products is no longer available for sale. We have removed it from your basket.',
        button_confirm: 'OK',
    },
    cross_selling: {
        title: 'Cross Selling',
    },
    home: 'Home',
    home_page: {
        initial: {
            surtitle: 'Welcome to',
            title: '{{title}} 🎉',
            content:
                'Make your choice below and let us take you on an unforgettable culinary journey.',
            cta1: 'Order',
            cta2: 'not used',
        },
        copied: 'Copied',
        alcohol_banner: {
            title: 'Ban on the sale of alcoholic beverages to minors under 18 years of age.',
            subtitle: `Proof of majority of the buyer is required at the time of online sale.`,
            code: 'PUBLIC HEALTH CODE, ART L3342 AND L3353-3',
        },
        consumption_modes: {
            surtitle: 'Welcome to',
            title: '{{title}} 🎉',
            cta1: 'Sit in',
            cta2: 'Take away',
        },
        table_number: ' (Table {{tableId}})',
    },
    incognito: 'Private browsing',
    incognito_page: {
        warning: '⚠️ Warning ⚠️ ',
        title: 'Private Browsing',
        copied: 'Copied',
        step_1: {
            content:
                '<strong>Impossible to order</strong> because you are in <strong>private browsing mode</strong>.',
            cta: 'What to do',
        },
        step_2: {
            content:
                '<strong>1. Copy</strong> the link<br><strong>2.</strong> Open your browser <strong>outside private browsing</strong><br><strong>3. Paste</strong> link into navigation bar',
            cta: 'Copy the link',
        },
    },
    login: 'Login',
    login_page: {
        title: 'Log in',
        content: 'Please enter your login details to start your order',
        inputs: {
            email: {
                label: 'E-mail address',
                placeholder: 'john.dott@innovorder.fr',
                bad_format: 'The e-mail format is invalid',
            },
            password: {
                label: 'Password',
                placeholder: 'Enter a password',
            },
            submit: 'Log in',
        },
        login_failed: 'Your credentials are invalid',
        uncatched_error: 'Unable to connect. Please try again later',
    },
    modal_order_notification: {
        title: 'Orders ready',
        i_understand: 'I understand',
        order_ready:
            'Your order <strong>{{ restaurantName, string }}</strong> is ready, you can go collect it',
    },
    modal_order_status: {
        title_waiting: 'Order waiting',
        title_processing: 'Order processing',
        title_cancelled: 'Order cancelled',
        title_ready: 'Order ready',
        title_collected: 'Order collected',
        i_understand: 'I understand',
        order_waiting:
            'Your order <strong>{{ restaurantName, string }}</strong> is <strong>waiting</strong>',
        order_processing:
            'Your order <strong>{{ restaurantName, string }}</strong> is <strong>processing</strong>',
        order_cancelled:
            'Your order <strong>{{ restaurantName, string }}</strong> is <strong>cancelled</strong>',
        order_ready:
            'Your order <strong>{{ restaurantName, string }}</strong> is <strong>ready</strong>, you can go collect it',
        order_collected:
            'Your order <strong>{{ restaurantName, string }}</strong> is <strong>collected</strong>',
    },
    modal_no_payment_method: {
        title: 'Oops...',
        content: 'No payment method is configured on this restaurant',
        button: 'Go back',
    },
};

export default translationEn;
