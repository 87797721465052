import { routes } from 'core/routes';
import { WorkflowStateContext, WorkflowStateEvent } from 'hooks/useWorkflowStateMachine/types';
import { createMachine } from 'xstate';

type WorkflowMachineProps = {
    isFoodCourt: boolean | null;
    isOrderFollowUpEnabled: boolean | undefined;
};

export const computeWorkflowStateMachine = ({
    isFoodCourt,
    isOrderFollowUpEnabled,
}: WorkflowMachineProps) =>
    createMachine<WorkflowStateContext, WorkflowStateEvent>({
        initial: 'home',
        predictableActionArguments: true,
        on: {
            INIT: routes.map(({ state }) => ({
                target: state,
                cond: (_context: WorkflowStateContext, event: WorkflowStateEvent) =>
                    event.state === state,
            })),
            RESET: {
                target: isFoodCourt ? 'restaurants' : 'menuHome',
                actions: isFoodCourt ? ['navigateToRestaurants'] : ['navigateToMenuHome'],
            },
            CURRENT_ORDER: {
                target:
                    isFoodCourt || isOrderFollowUpEnabled ? 'orderFollowUp' : 'orderConfirmation',
                actions:
                    isFoodCourt || isOrderFollowUpEnabled
                        ? ['navigateToOrderFollowUp']
                        : ['navigateToOrderConfirmation'],
            },
            CART: {
                target: 'cart',
                actions: ['navigateToCart'],
            },
        },
        states: {
            home: {
                on: {
                    NEXT: {
                        target: isFoodCourt ? 'restaurants' : 'menuHome',
                        actions: isFoodCourt ? ['navigateToRestaurants'] : ['navigateToMenuHome'],
                    },
                },
            },
            login: {},
            restaurants: {
                on: {
                    PREVIOUS: {
                        target: 'home',
                        actions: ['navigateToHome'],
                    },
                    NEXT: {
                        target: 'menuHome',
                        actions: ['navigateToMenuHome'],
                    },
                },
            },
            menuHome: {
                on: {
                    PREVIOUS: {
                        target: isFoodCourt ? 'restaurants' : 'home',
                        actions: isFoodCourt ? ['navigateToRestaurants'] : ['navigateToHome'],
                    },
                    NEXT: {
                        target: 'cart',
                        actions: ['navigateToCart'],
                    },
                },
            },
            cart: {
                on: {
                    PREVIOUS: {
                        target: 'menuHome',
                        actions: ['navigateToMenuHome'],
                    },
                    NEXT: isFoodCourt
                        ? [
                              {
                                  target: 'userInformation',
                                  actions: ['navigateToUserInformation'],
                                  cond: (_context, event) => !!event.crossSellingAlreadyTriggered,
                              },
                              {
                                  target: 'crossSelling',
                                  actions: ['navigateToCrossSelling'],
                                  cond: (_context, event) => !event.crossSellingAlreadyTriggered,
                              },
                          ]
                        : {
                              target: 'userInformation',
                              actions: ['navigateToUserInformation'],
                          },
                },
            },
            crossSelling: {
                on: {
                    NEXT: {
                        target: 'userInformation',
                        actions: ['navigateToUserInformation'],
                    },
                },
            },
            userInformation: {
                on: {
                    PREVIOUS: {
                        target: 'cart',
                        actions: ['navigateToCart'],
                    },
                    NEXT: {
                        target: 'payment',
                        actions: ['navigateToChoosePayment'],
                    },
                },
            },
            payment: {
                on: {
                    PREVIOUS: {
                        target: 'userInformation',
                        actions: ['navigateToUserInformation'],
                    },
                    NEXT: {
                        target:
                            isFoodCourt || isOrderFollowUpEnabled
                                ? 'orderFollowUp'
                                : 'orderConfirmation',
                        actions:
                            isFoodCourt || isOrderFollowUpEnabled
                                ? ['navigateToOrderFollowUp']
                                : ['navigateToOrderConfirmation'],
                    },
                },
            },
            orderConfirmation: {
                on: {
                    ERROR: {
                        target: 'payment',
                    },
                },
            },
            orderFollowUp: {
                on: {
                    ERROR: {
                        target: 'payment',
                    },
                },
            },
            notFound: {},
        },
    });
